/* globals nx, s */

export function doAdobeCategory(category) {
    // evar112 - set in the backend on add to cart
    s.events = 'event158';
    s.linkTrackEvents = 'event158';  
    s.linkTrackVars = 'prop16,prop25,prop26,prop27,prop58,eVar25,eVar26,eVar27,eVar58';
    s.prop16 = category;
    s.tl(true, 'o', 'masthead navigation click');
}
