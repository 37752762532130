/* globals s, nx */
var megaNav = function megaNav() {
  this.initialize.apply(this, arguments);
};

megaNav.prototype.initialize = function initialize() {
  var obj = this;

  if (!nx.ute.readDatalayer(['global', 'streams', 'meganav'])) {
    return;
  }

  obj.active = false;

  document.querySelector('.masthead-wrapper').classList.add('mega-nav');

  obj.attachShopProducts();
  obj.initialize_bg();
  obj.initializeElements(document);
  obj.setupLevel2();

  document.querySelector('.mob-nav-button').addEventListener('click', function(evt) {
    obj.mobileMenu(this, evt);
  });
};

megaNav.prototype.attachShopProducts = function attachShopProducts() {
  const shop_btn = document.getElementById('shop_products');
  var obj = this;

  if (shop_btn) {
    shop_btn.addEventListener('click', el => {
      document.querySelector('.masthead-menu-zone2 > div:first-child > div:last-child').classList.remove('hide_categories');
      document.querySelector('.masthead-menu-zone2 > div:first-child > div:last-child').classList.add('expanded-nav');
      document.querySelector('#l1_categories').classList.add('expanded-nav');
      document.querySelector('.mega-bg').classList.add('expanded');
      document.getElementsByTagName('body')[0].classList.add('no_small_scroll');
    });

    document.querySelector('#l1_header button').addEventListener('click', el => {
      const the_nav = document.getElementById('l1_categories');

      the_nav.querySelectorAll('.nav-open').forEach(function (l1) {
        l1.classList.remove('nav-open');
      });
      obj.initialiseLazyLoaders(the_nav);

      /* TODO: Put this in a method that other people can call */
      document.querySelector('.mob-nav-button').classList.remove('mob-nav-button-active');
      document.querySelector('.masthead-wrapper').classList.remove('expanded');
      document.querySelector('.masthead-wrapper').classList.remove('sub-expanded');
      document.querySelector('.mega-bg').classList.remove('expanded-nav');
      document.querySelector('.mega-bg').classList.remove('expanded');
      document.getElementsByTagName('body')[0].classList.remove('no_scroll');
      document.getElementsByTagName('body')[0].classList.remove('no_small_scroll');
      document.querySelector('#l1_categories').classList.remove('expanded-nav');
      document.querySelector('.masthead-menu-zone2 > div:first-child > div:last-child').classList.add('hide_categories');
      document.querySelector('.masthead-menu-zone2 > div:first-child > div:last-child').classList.remove('expanded-nav');
      document.getElementById('level2_holder').classList.add('hide_categories');
      document.getElementById('level3_holder').classList.add('hide_categories');
    });
  }

  return;
};

megaNav.prototype.initialize_bg = function initialize_bg() {
  var obj = this;

  document.getElementsByClassName('mega-bg')[0]
    .addEventListener('click', el => {
      obj.cleanLevel3();
      obj.cleanLevel2();

      /* Optimise me */
      document.querySelectorAll('.nav-open')
        .forEach(d => {
          d.classList.remove('nav-open');
        }
      );

      document.querySelector('.mob-nav-button').classList.remove('mob-nav-button-active');

      document.querySelectorAll('#level2_holder, #nav_container, .masthead-menu-zone2 > div:first-child > div:last-child, #bg_holder div, .mega-bg').forEach(d => {
        d.classList.remove('expanded-nav');
      });
      document.querySelector('.masthead-wrapper').classList.remove('expanded');
      document.querySelector('.mega-bg').classList.remove('expanded');
      document.getElementById('meganav').classList.add('hide_categories');
      document.getElementById('level3_holder').classList.add('hide_categories');
      document.getElementsByTagName('body')[0].classList.remove('no_scroll');
      document.getElementsByTagName('body')[0].classList.remove('no_small_scroll');

      obj.initializeElements(document);
  });

  return;
};

megaNav.prototype.initializeElements = function initializeElements(doc) {
  var obj = this;
  var l, mb, menu_dropdown_id, mb_dropdown;

  /* Load visible menu */
  const loaders = doc.querySelectorAll('.nav-loader');
  for (let i = 0; i < loaders.length; i++) {
    l = loaders[i];
    obj.menuLoader(l, doc);
  }

  obj.initialiseLazyLoaders(doc);

  const menu_buttons = doc.querySelectorAll('.nav-button');
  for (let i = 0; i < menu_buttons.length; i++) {
    mb = menu_buttons[i];
    menu_dropdown_id = mb.getAttribute('aria-controls');
    mb_dropdown = document.getElementById(menu_dropdown_id);

    mb.registry ??= {};
    mb.registry.click ??= [];
    mb.registry.click.forEach(h => mb.removeEventListener('click', h));
    mb.registry.click = [];

    let mbClickHandler = obj.toggleMenu.bind(obj, mb, mb_dropdown);
    mb.registry.click.push(mbClickHandler);
    mb.addEventListener('click', mbClickHandler);
  }
};

megaNav.prototype.initialiseLazyLoaders = function initialiseLazyLoaders(doc) {
  var obj = this;
  var ll;

  const lazyloaders = doc.querySelectorAll('.nav-lazy-loader:not(.nav-open)');

  for (let i = 0; i < lazyloaders.length; i++) {
    ll = lazyloaders[i];

    ll.registry ??= {};
    ll.registry.click ??= [];
    ll.registry.click.forEach(h => ll.removeEventListener('click', h));
    ll.registry.click = [];

    let llClickHandler = obj.lazyExpand.bind(obj, ll, doc);
    ll.registry.click.push(llClickHandler);
    ll.addEventListener('click', llClickHandler);
  }
};

megaNav.prototype.menuLoader = function menuLoader(l, doc) {
  var obj = this;
  const endpoint = l.getAttribute('data-url');
  const is_l3 = endpoint.match(/level3/);

  l.innerHTML = '<img src="/media/images/new/ajax-mini.gif" />';

  fetch(endpoint, { method: 'GET' })
    .then(response => response.text())
    .then(data => {
      /* On success or failure, we don't want to retry the
       * loader. Either will cause a request loop. */
      l.innerHTML = data;
      l.classList.remove('nav-loader');

      const order_rules = document.getElementsByClassName('opi-tile');
      if (order_rules.length) {
        const mega_top = document.getElementById('meganav').getBoundingClientRect().top;
        const mast_top = document.getElementsByClassName('scrolling-masthead')[0].getBoundingClientRect().top;
        order_rules[0].style.top = (mega_top - mast_top - 2) + 'px';
      }

      obj.initializeElements(doc);
    });
};

megaNav.prototype.cleanLevel3 = function cleanLevel3() {
  const l3 = document.getElementById("level3_container");

  if (l3) {
    l3.innerHTML = '<div id="l3_left">' +
      '<div></div></div>' +
      '<div id="l3_right"><div></div></div>';
  }
};

megaNav.prototype.cleanLevel2 = function cleanLevel2() {
  var obj = this;
  const level2 = document.querySelector('#level2_holder ul');

  if (level2) {
    level2.querySelectorAll('li')
      .forEach(l2 => {
        l2.removeEventListener('click', obj.expandToggle); // free memory
        l2.remove();
      }
    );
  }
};

megaNav.prototype.toggleMenu = function toggleMenu(button, dropdown, ev) {
  var icon, dls;

  if (ev) {
    var element = ev.target;
    if (element.tagName == 'A') return;
    if (element.tagName == 'INPUT') return;
    ev.stopPropagation();
    ev.preventDefault();
  }

  icon = button.querySelector('.fa');
  if (dropdown.classList.contains('expanded')) {
    icon.className = 'fa fa-chevron-down';
    button.setAttribute('aria-expanded', false);
    this.collapseMenu(dropdown, button);
  } else {
    icon.className = 'fa fa-chevron-up';
    button.setAttribute('aria-expanded', true);
    this.expandMenu(dropdown, button);
  }
};

megaNav.prototype.mobileMenu = function mobileMenu(button, ev) {
  var icon, dls;
  const really_mobile = (window.innerWidth < 481); // phone-lg breakpoint

  if (ev) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  const l1_holder = document.querySelector('.masthead-menu-zone2 > div:first-child > div:last-child');
  const l1_cat = document.getElementById('l1_categories');
  const mn = document.getElementById('meganav');
  const wrapper = document.querySelector('.masthead-wrapper');
  const shop_holder = document.getElementById('shop_by_holder');
  const shop_button = document.getElementById('shop_heading');

  if (button.classList.contains('mob-nav-button-active')) {
    button.classList.remove('mob-nav-button-active');
    document.getElementById('masthead_mobile').classList.remove('fixed');
    document.getElementsByTagName('body')[0].classList.remove('no_small_scroll');
    document.getElementsByTagName('body')[0].classList.remove('no_scroll');

    l1_holder.classList.add('hide_categories');
    l1_holder.classList.remove('expanded-nav');
    l1_cat.classList.remove('expanded-nav');
    l1_cat.querySelectorAll('.nav-open').forEach(function (l1) {
      l1.classList.remove('nav-open');
    });
    wrapper.classList.remove('expanded');
    wrapper.classList.remove('sub-expanded');
    document.querySelector('.mega-bg').classList.remove('expanded');
    mn.classList.add('hide_categories');
  } else {
    button.classList.add('mob-nav-button-active');
    document.getElementById('masthead_mobile').classList.add('fixed');
    document.getElementsByTagName('body')[0].classList.add('no_small_scroll');

    l1_holder.classList.remove('hide_categories');
    l1_holder.classList.add('expanded-nav');
    l1_cat.classList.add('expanded-nav');
    wrapper.classList.add('expanded');
    shop_button.click();
    document.querySelector('.mega-bg').classList.add('expanded');
  }
};

megaNav.prototype.closeMenu = function closeMenu(button, dropdown, ev) {
  if (
    dropdown.classList.contains('menu-open') &&
    !dropdown.contains(ev.target)
  ) {
    this.collapseMenu(dropdown, button);
  }
};

megaNav.prototype.collapseMenu = function collapseMenu(dropdown, button) {
  dropdown.classList.remove('expanded');

  setTimeout(function() {
    dropdown.focus();
  }, 501); // CSS animation needs to finish
};

megaNav.prototype.expandMenu = function expandMenu(dropdown, button) {
  dropdown.classList.add('expanded');

  const mobile_expanded = document.querySelector('.mega-nav.expanded');
  if (mobile_expanded) {
    setTimeout(function() {
      const dummy = dropdown.getElementsByTagName('a');
    }, 501); // CSS animation needs to finish
    return;
  }

  setTimeout(function() {
    dropdown.getElementsByTagName('a')[0].focus();
  }, 501); // CSS animation needs to finish
};

megaNav.prototype.lazyExpand = function lazyExpand(umenu, doc, ev) {
  /* LazyExpand - AJAX call to get the next level of categories */
  var element, contents, contents_id;
  var obj = this;
  if (ev) {
    element = ev.target;
    if (element.tagName == 'A' && !element.classList.contains('nav-expand-href')) {
      nx.navigation.doAdobeCategory(element.getAttribute('data-category'));
      return;
    }
    ev.stopPropagation();
    ev.preventDefault();
  }

  if (obj.active) {
    return;
  }

  document.querySelector('.masthead-wrapper').classList.add('sub-expanded');

  const endpoint = umenu.getAttribute('data-url');
  const is_l3 = endpoint.match(/level3/);
  let output;

  if (is_l3) {
    output = '#level3_holder';

    contents = document.querySelector(output);
    if (!contents) return;

    obj.active = true;

    document.getElementById('level2_holder').classList.add('hide_categories');
    document.getElementById('level3_holder').classList.remove('hide_categories');
  } else {
    output = '#level2_holder nav header';

    contents = document.querySelector(output);
    if (!contents) return;

    obj.active = true;

    document.querySelector('.masthead-menu-zone2 > div:first-child > div:last-child').classList.add('hide_categories');
    document.querySelector('#l1_categories').classList.remove('expanded-nav');
    document.getElementById('meganav').classList.remove('hide_categories');
    document.getElementById('level2_holder').classList.remove('hide_categories');

    document.querySelectorAll('#bg_holder div, #nav_holder > div:first-child, #nav_holder > div:first-child > div:first-child')
      .forEach(d => {
        d.classList.add('expanded-nav');
      }
    );
  }

  obj.expandToggle(umenu);

  contents.querySelectorAll('li')
    .forEach(l2 => {
      l2.removeEventListener('click', obj.expandToggle); // free memory
      l2.remove();
    }
  );
  obj.cleanLevel3();

  if (is_l3) {
    contents.innerHTML = '<ul><li><i class="fa fa-spinner fa-spin"></i></li></ul>';
  } else {
    const u = contents.parentNode.querySelector('.categories_container');
    if (u) {
      u.remove(); // remove level2
    }
    contents.insertAdjacentHTML('afterend', '<ul><li><i class="fa fa-spinner fa-spin"></i></li></ul>');
  }

  fetch(endpoint, { method: 'GET' })
    .then(response => (response.status == '200' ? response.text() : ''))
    .then(data => {
      var base_element;
      let parts = [ ];

      if (is_l3) {
        if ((/<p id="NO_LEVEL_C"/s).test(data)) { // Missing level C categories, so bounce
          const link = umenu.querySelector('a');
          if (link) {
            nx.navigation.doAdobeCategory(link.getAttribute('data-category'));
            window.location = link.href;
          }
          return;
        }

        contents.innerHTML = data;
        base_element = contents;
      } else {
        if ((/<p id="NO_LEVEL_B"/s).test(data)) { // Missing level B categories, so bounce
          window.location = umenu.querySelector('a').href;
          return;
        }

        base_element = contents.parentNode;
        base_element.querySelector('ul').remove(); // remove spinner

        if ((/<p id="NO_LEVEL_C"/s).test(data)) { // Missing level C categories, so bounce
            window.location = umenu.querySelector('a').href;
            return;
        } else if ((/<level3\s*>.+<\/level3>/s).test(data)) {
            parts = (/^(.+)<level3\s*>(.+)<\/level3>/s).exec(data);
        } else {
            parts[1] = data;
        }
        contents.insertAdjacentHTML('afterend', parts[1]);

        if (parts[2]) {
            document.querySelector('#level3_holder').innerHTML = parts[2];
        }
      }

      // umenu.classList.remove('nav-lazy-loader');
      document.querySelectorAll('#bg_holder div, #nav_holder > div:first-child, #nav_holder > div:first-child > nav ul, .mega-bg')
        .forEach(d => {
          d.classList.add('expanded-nav');
        }
      );
      document.getElementsByTagName('body')[0].classList.add('no_scroll');

      if (is_l3) {
        document.getElementById('go_level2').addEventListener('click', el => {
          const the_nav = document.getElementById('level2_holder');

          the_nav.querySelectorAll('.nav-open').forEach(function (l1) {
            l1.classList.remove('nav-open');
          });
          obj.initialiseLazyLoaders(the_nav);

          document.getElementById('level2_holder').classList.remove('hide_categories');
          document.getElementById('level3_holder').classList.add('hide_categories');
        });
        document.querySelector('#level3_holder .fa-close').addEventListener('click', el => {
          document.querySelectorAll('#bg_holder div').forEach(d => { d.classList.remove('expanded-nav') });
          document.querySelector('.mob-nav-button').classList.remove('mob-nav-button-active');
          document.querySelector('.mega-bg').classList.remove('expanded-nav');
          document.querySelector('.mega-bg').classList.remove('expanded');
          document.querySelector('#l1_categories').classList.remove('expanded-nav');
          document.querySelector('.masthead-menu-zone2 > div:first-child > div:last-child').classList.remove('expanded-nav');
          document.querySelector('#nav_container').classList.remove('expanded-nav');

          document.getElementById('meganav').classList.add('hide_categories');
          document.getElementById('level2_holder').classList.add('hide_categories');
          document.getElementById('level3_holder').classList.add('hide_categories');
          const the_nav = document.getElementById('l1_categories');

          the_nav.querySelectorAll('.nav-open').forEach(function (l1) {
            l1.classList.remove('nav-open');
          });
          document.querySelector('.masthead-wrapper').classList.remove('sub-expanded');
          document.querySelector('.masthead-wrapper').classList.remove('expanded');

          document.getElementsByTagName('body')[0].classList.remove('no_scroll');
          document.getElementsByTagName('body')[0].classList.remove('no_small_scroll');
          obj.initialiseLazyLoaders(the_nav);
        });
      }

      obj.initializeElements(base_element, is_l3);

      setTimeout(function() {
        let first_choice = contents.querySelector('li:first-child a');

        if (!first_choice) {
          first_choice = contents.nextElementSibling
                            ? contents.nextElementSibling.querySelector('li:first-child a')
                            : contents;
        }
        first_choice.focus();
      }, 501); // Give time for CSS animation to finish

      umenu.registry ??= {};
      umenu.registry.click ??= [];
      umenu.registry.click.forEach(h => umenu.removeEventListener('click', h));
      umenu.registry.click = [];

      let umenuClickHandler = obj.expandToggle.bind(obj, umenu);
      umenu.registry.click.push(umenuClickHandler);
      umenu.addEventListener('click', umenuClickHandler);

      obj.active = false; // Allow menu to be called again
    });
};

megaNav.prototype.setupLevel2 = function setupLevel2() {
  const obj = this;

  /* Go back to Level A */
  document.querySelector('#level2_holder .all_categories').addEventListener('click', el => {
    const the_nav = document.getElementById('l1_categories');

    the_nav.querySelectorAll('.nav-open').forEach(function (l1) {
      l1.classList.remove('nav-open');
    });
    obj.initialiseLazyLoaders(the_nav);

    document.querySelector('.masthead-menu-zone2 > div:first-child > div:last-child').classList.add('expanded-nav');
    document.querySelector('.masthead-menu-zone2 > div:first-child > div:last-child').classList.remove('hide_categories');
    document.querySelector('.masthead-menu-zone2 > div:first-child').classList.remove('hide_categories');
    document.querySelector('#nav_container').classList.remove('expanded-nav');
    document.querySelector('#meganav').classList.add('hide_categories');
    document.querySelector('#l1_categories').classList.add('expanded-nav');
    document.querySelector('.masthead-wrapper').classList.remove('sub-expanded');
  });

  document.querySelector('#level2_holder .fa-close').addEventListener('click', el => {
    document.querySelectorAll('#bg_holder div').forEach(d => { d.classList.remove('expanded-nav') });
    document.querySelector('.mob-nav-button').classList.remove('mob-nav-button-active');
    document.querySelector('.mega-bg').classList.remove('expanded-nav');
    document.querySelector('.mega-bg').classList.remove('expanded');
    document.querySelector('#l1_categories').classList.remove('expanded-nav');
    document.querySelector('.masthead-menu-zone2 > div:first-child > div:last-child').classList.remove('expanded-nav');
    document.querySelector('#nav_container').classList.remove('expanded-nav');

    document.getElementById('meganav').classList.add('hide_categories');
    document.getElementById('level3_holder').classList.add('hide_categories');
    document.getElementById('level2_holder').classList.add('hide_categories');
    const the_nav = document.getElementById('l1_categories');

    the_nav.querySelectorAll('.nav-open').forEach(function (l1) {
      l1.classList.remove('nav-open');
    });

    document.querySelector('.masthead-wrapper').classList.remove('sub-expanded');
    document.querySelector('.masthead-wrapper').classList.remove('expanded');

    document.getElementsByTagName('body')[0].classList.remove('no_scroll');
    document.getElementsByTagName('body')[0].classList.remove('no_small_scroll');
    obj.initialiseLazyLoaders(the_nav);
  });
};

megaNav.prototype.expandToggle = function expandToggle(umenu, ev) {
  // expandToggle - Indicate that the option was chosen or follow the link
  var element;
  var proceed;
  var isOpen = umenu.classList.contains('nav-open');

  if (ev) {
    element = ev.target;
    // if someone has clicked on an open category header, then follow the link
    proceed = element.classList.contains('nav-expand-href') && !isOpen;
    if (element.tagName == 'A' && !proceed) {
      nx.navigation.doAdobeCategory(element.getAttribute('data-category'));
      return;
    }

    ev.stopPropagation();
    ev.preventDefault();
  }
  this.collapseAll(umenu);

  if (!isOpen) {
    umenu.classList.add('nav-open');
    this.initialiseLazyLoaders(umenu.parentElement); // Reattach lazyExpand
  }
};

megaNav.prototype.collapseAll = function collapseAll(clickedMenu) {
  // grab the containing structure
  clickedMenu = clickedMenu.parentElement.parentElement;
  if (!clickedMenu) {
    return;
  }
  //collapse all its sections
  clickedMenu.querySelectorAll('.nav-open').forEach(function (el) {
    el.classList.remove('nav-open');
  });
};

export default megaNav;
